<template>
    <div id="app">
        <router-view @authenticated="setAuthenticated" />
    </div>
</template>

<script>
    export default {
        name: 'App',
        data() {
            return {
                authenticated: false,
                mockAccount: {
                    username: "test",
                    password: "test1234"
                }
            }
        },
        mounted() {
            if(!this.authenticated) {
                this.$router.replace({ name: "Login" });
            }
        },
        methods: {
            setAuthenticated(status) {
                this.authenticated = status;
            },
            logout() {
                this.authenticated = false;
            }
        }
    }
</script>

<style lang="scss">
@import "assets/styles/common/fonts";

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #202020;
  font-family: Roboto;
/*
    background: url(assets/imgs/bgs/bg1.png) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
*/
}

</style>

